.sidepanel{
    /* display: flex; */
    justify-content: flex-start; 
    width: 50px;
    padding: 10px;
    position: relative;
}

.list {
    user-select: none;
    display: flex;
    justify-content: space-between;
    /* background-color: rgb(0, 149, 255) */
}

  .item {
    flex-shrink: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    color: rgb(62, 62, 62);
    /* margin: 8px; */
    padding-right: 30px;
    padding-left: 15px;
    /* font-family: sans-serif; */
  }

  .sidebar {
    display: flex;
    margin: 1px;
    padding: 8px;
    /* background-color: #f5f6f9; */
    /* background-color: white; */
    background-color: #2c7be5;
    border: 1px;
    box-shadow: inset;
  }