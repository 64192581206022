.rx_header_wrapper {
  /* background-color: #4b95d6; */
  background-color: white;
  padding: 2px 0;
  position: relative;
  box-shadow: 0 12px 12px rgba(0, 0, 0, 0.16);
}
.rx_header_wrapper .rx_header_inner {
  display: flex;
  align-items: center;
  justify-content: space-between;
  /* .hamburger {
    display: none !important;
  } 
  .rx_header_profle {
    margin-right: 30px !important;
  }*/
}
.rx_header_wrapper .rx_header_inner .main_menu nav ul {
  display: flex;
}
.rx_header_wrapper .rx_header_inner .main_menu nav ul li:first-child a {
  padding-left: 0;
}
.rx_header_wrapper .rx_header_inner .main_menu nav ul li a {
  display: block;
  font-size: 18px;
  font-weight: 500;
  color: rgba(255, 255, 255, 0.73);
  padding: 0 20px;
  transition: all 0.3s ease-in-out;
}
.rx_header_wrapper .rx_header_inner .main_menu nav ul li a:hover {
  color: #fff;
}
.rx_header_wrapper .rx_header_inner .main_menu nav ul li a.active {
  color: #fff;
}
.rx_header_wrapper .rx_header_inner .rx_header_profle {
  margin-right: 25px;
}
.rx_header_wrapper .rx_header_inner .rx_header_profle .pro_dropdown li {
  position: relative;
}
.rx_header_wrapper
  .rx_header_inner
  .rx_header_profle
  .pro_dropdown
  li
  .cl_name {
  display: block;
  font-size: 18px;
  font-weight: 600;
  color: #fff;
  position: relative;
}
.rx_header_wrapper
  .rx_header_inner
  .rx_header_profle
  .pro_dropdown
  li
  .cl_name::after {
  display: none !important;
}
.rx_header_wrapper
  .rx_header_inner
  .rx_header_profle
  .pro_dropdown
  li
  .cl_name::before {
  content: url(../images/icons/angle-down.svg);
  position: absolute;
  right: -20px;
  top: 48%;
  transform: translateY(-50%);
}
.rx_header_wrapper .rx_header_inner .rx_header_profle .pro_dropdown li ul {
  position: absolute;
  right: -30px;
  top: 52px;
  padding: 10px;
  box-shadow: 0 10px 40px 0 rgba(104, 113, 123, 0.2);
  border: 1px solid #ebecf4;
  background-color: #fff;
  border-radius: 3px;
  min-width: 170px;
  opacity: 0;
  visibility: hidden;
  transition: all 0.3s ease-in-out;
}
.rx_header_wrapper
  .rx_header_inner
  .rx_header_profle
  .pro_dropdown
  li
  ul.active {
  opacity: 1;
  visibility: visible;
  width: max-content;
}
.rx_header_wrapper
  .rx_header_inner
  .rx_header_profle
  .pro_dropdown
  li
  ul::after {
  content: '';
  top: -13px;
  position: absolute;
  right: 7px;
  width: max-content;
  height: 0;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-bottom: 13px solid #fff;
  z-index: 3333;
}
.rx_header_wrapper .rx_header_inner .rx_header_profle .pro_dropdown li ul li a {
  display: block;
  font-size: 16px;
  color: #6c757d;
  padding: 5px;
  text-transform: capitalize;
  width: max-content;
}
.rx_header_wrapper .rx_header_inner .hamburger {
  display: none;
  position: absolute;
  top: 50%;
  right: 15px;
  transform: translateY(-50%);
}
.rx_header_wrapper .rx_header_inner .hamburger a {
  display: block;
}
.rx_header_wrapper .rx_header_inner .hamburger a img {
  width: 36px;
}
.rx_header_wrapper .rx_header_inner.main_menu {
  display: none;
}

.middle_section {
  padding: 1px 0 90px;
  min-height: 82vh;
}
.middle_section .main_heading h1 {
  margin-bottom: 20px;
}
.middle_section .dashboard_inner {
  background-color: #fff;
  padding: 19px 15px 20px;
  border-radius: 12px;
  box-shadow: 0 0 12px rgba(0, 0, 0, 0.08);
}
.middle_section .dashboard_header {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 10px;
}
.middle_section .dashboard_middle .main_heading h1 {
  margin-bottom: 5px !important;
}
.middle_section .rx_btns {
  margin-top: 3px;
}
.middle_section .rx_btns ul {
  display: flex;
  justify-content: flex-end;
}
.middle_section .rx_btns ul li:first-child {
  margin-right: 36px;
}

.rx_table_wrapper {
  padding-bottom: 16px;
}
.rx_table_wrapper table {
  width: 100%;
}
.rx_table_wrapper table tr {
  border-bottom: 1px solid #dddddd;
}
.rx_table_wrapper table tr:last-child {
  border-bottom: none;
}
.rx_table_wrapper table tr th {
  font-size: 14px;
  font-weight: 600;
  color: #323a46;
  text-transform: capitalize;
  vertical-align: middle;
  padding: 16px 14px 13px;
  background-color: #a9d7ff;
}
.rx_table_wrapper table tr td {
  font-size: 14px;
  font-weight: 400;
  color: #6c757d;
  vertical-align: middle;
  padding: 10px 14px;
}
.rx_table_wrapper table .firm_name {
  color: #2c7be5;
}
.rx_table_wrapper table .user {
  background-color: #f3f7f9;
  width: 24px;
  height: 19px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  font-family: 'Open Sans', sans-serif;
  margin-left: auto;
}
.rx_table_wrapper table .activate {
  font-size: 12px;
  color: #00539f;
  font-weight: 400;
  background-color: rgba(0, 83, 159, 0.12);
  max-width: 69px;
  height: 19px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-transform: capitalize;
  font-family: 'Open Sans', sans-serif;
}
.rx_table_wrapper table .deactivate {
  background-color: #fdd8d8;
  color: #f86262;
}
.rx_table_wrapper table .edit {
  width: 32px;
  height: 28px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #f3f7f9;
  border-radius: 4px;
}
.rx_table_wrapper .dashboard_table tr td:nth-child(1) {
  min-width: 100px;
}
.rx_table_wrapper .dashboard_table tr td:nth-child(2) {
  min-width: 150px;
}
.rx_table_wrapper .dashboard_table tr td:nth-child(3) {
  min-width: 140px;
}
.rx_table_wrapper .dashboard_table tr td:nth-child(4) {
  min-width: 200px;
}
.rx_table_wrapper .dashboard_table tr td:nth-child(5) {
  min-width: 150px;
}
.rx_table_wrapper .dashboard_table tr td:nth-child(6) {
  min-width: 130px;
}
.rx_table_wrapper .dashboard_table tr td:nth-child(7) {
  min-width: 150px;
}
.rx_table_wrapper .dashboard_table tr td:nth-child(8) {
  min-width: 100px;
}
.rx_table_wrapper .dashboard_table tr td:nth-child(9) {
  min-width: 40px;
}
.rx_table_wrapper .dashboard_table tr td:nth-child(10) {
  min-width: 40px;
}
.padding-fix {
  padding-bottom: 0;
  margin-bottom: 0;
}
.support-link {
  color: #2c7be5;
}

.rx_footer {
  background-color: #edeff4;
  padding: 19px 0 17px;
  /*  position: absolute;
  left: 0;
  right: 0;
  bottom: 0; */
}
.rx_footer .footer_left p {
  font-size: 14px;
  line-height: 21px;
  margin: 0;
  font-weight: 400;
  color: #818d93;
}
.rx_footer .footer_right ul {
  display: flex;
  justify-content: flex-end;
}
.rx_footer .footer_right ul li a {
  display: block;
  font-size: 14px;
  line-height: 21px;
  margin: 0;
  font-weight: 400;
  color: #818d93;
  padding-left: 25px;
  text-transform: capitalize;
}

.cls_table tr {
  border-bottom: none !important;
}

.header_user_name {
  color: #ffffffe6;
}

.header_client_name {
  color: rgb(62, 62, 62);
}

.error_table {
  width: 750px;
}

/************ Responsive ************/
@media (max-width: 1440px) {
  .rx_header_wrapper .rx_header_inner .main_menu nav ul li a {
    padding: 0 30px;
  }

  .rx_table_wrapper .dashboard_table tr td:nth-child(1) {
    min-width: 275px;
  }
  .rx_table_wrapper .dashboard_table tr td:nth-child(5) {
    min-width: 105px;
  }
  .rx_table_wrapper .dashboard_table tr td:nth-child(7) {
    min-width: 115px;
  }
}
@media (max-width: 1199px) {
  .middle_section {
    padding: 40px 0 50px;
  }

  .rx_header_wrapper .rx_header_inner .main_menu nav ul li a {
    font-size: 18px;
    padding: 0 15px;
  }
  .rx_header_wrapper .rx_header_inner .rx_header_profle {
    margin-right: 30px;
  }
  .rx_header_wrapper
    .rx_header_inner
    .rx_header_profle
    .pro_dropdown
    li
    .cl_name {
    font-size: 18px;
  }
  .rx_header_wrapper .rx_header_inner .rx_header_profle .pro_dropdown li ul {
    min-width: 120px;
  }
}
@media (max-width: 991px) {
  .rx_header_wrapper .rx_header_inner .main_menu nav ul li a {
    font-size: 16px;
    padding: 0 10px;
  }
}
@media (max-width: 768px) {
  .rx_header_wrapper .rx_header_inner .logo img {
    max-width: 100px;
  }
  .rx_header_wrapper .rx_header_inner .main_menu {
    position: fixed;
    top: 0;
    bottom: 0;
    left: -200px;
    width: 200px;
    background-color: #4b95d6;
    transition: all 0.3s ease-in-out;
    z-index: 999;
  }
  .rx_header_wrapper .rx_header_inner .main_menu.active {
    left: 0;
  }
  .rx_header_wrapper .rx_header_inner .main_menu nav {
    padding-top: 30px;
  }
  .rx_header_wrapper .rx_header_inner .main_menu nav ul {
    flex-direction: column;
  }
  .rx_header_wrapper .rx_header_inner .main_menu nav ul li:first-child a {
    padding: 10px 25px;
  }
  .rx_header_wrapper .rx_header_inner .main_menu nav ul li a {
    padding: 10px 25px;
  }
  .rx_header_wrapper .rx_header_inner .rx_header_profle {
    margin-right: 70px;
  }
  .rx_header_wrapper
    .rx_header_inner
    .rx_header_profle
    .pro_dropdown
    li
    .cl_name {
    font-size: 15px;
  }
  .rx_header_wrapper .rx_header_inner .rx_header_profle .pro_dropdown li ul {
    min-width: 109px;
  }
  .rx_header_wrapper .rx_header_inner .hamburger {
    display: block;
  }
}
@media (max-width: 767px) {
  .middle_section {
    padding: 40px 0 70px;
  }
  .middle_section .dashboard_inner {
    padding: 19px 0 30px 5px;
  }

  .middle_section .rx_btns ul {
    justify-content: center !important;
  }
  .middle_section .rx_btns ul li:first-child {
    margin-right: 12px;
  }
}
@media (max-width: 575px) {
  .middle_section .main_heading h1 {
    margin-bottom: 20px;
  }

  .rx_footer .footer_left {
    margin-bottom: 15px;
    text-align: center;
  }
  .rx_footer .footer_right ul {
    justify-content: center;
  }
  .rx_footer .footer_right ul li:first-child a {
    padding: 0;
  }
} /*# sourceMappingURL=dashboard.css.map */
