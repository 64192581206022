.rx_login_wrapper {
  background-color: #0E3146;
  background-size: cover;
  background-repeat: no-repeat;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
  min-height: 100vh;
  overflow-y: auto;
  /* padding: 15px 10px; */
}
.rx_login_wrapper .rx_login_inner {
  /* max-width: 478px; */
  height: 100vh;
  width: 100%;
  background: white;
  /* border-radius: 12px; */
  padding: 33px 45px;
  overflow-y: auto;
  width: 40%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.rx_login_wrapper .rx_login_inner .login_header {
  text-align: center;
}
.rx_login_wrapper .rx_login_inner .login_header .login_logo {
  width: 160px;
  height: 47px;
  margin: auto;
}
.left_login_section{
  width: 50%;
    height: 100vh;
    justify-content: center;
    display: flex;
    flex-direction: column;
    padding: 60px;
    row-gap: 20px;
}
.rx_login_wrapper .rx_login_inner .login_header p {
  font-size: 20px;
  font-weight: 400;
  line-height: 30px;
  max-width: 300px;
  margin: 0 auto;
  color: #707070;
}
.rx_login_wrapper .rx_login_inner .login_header h5 {
  font-size: 20px;
  color: #3283f6;
  line-height: 29px;
  text-transform: uppercase;
  margin-top: 18px;
  margin-bottom: 25px;
  font-weight: 700;
}
.bottom-buttons {
  padding-top: 10px;
  display: flex;
  justify-content: space-between;
}
.rx_login_wrapper .rx_login_inner .rx_form_block .rx_form_field {
  height: 42px;
}
.rx_login_wrapper .rx_login_inner .rx_form_checkbox {
  margin-top: -15px;
}
.rx_login_wrapper .rx_login_inner .login_btn {
  margin-top: 33px;
}
.rx_login_wrapper .login_footer {
  text-align: center;
}
.rx_login_wrapper .login_footer a {
  display: inline-block;
  font-size: 14px;
  color: #fff;
  line-height: 21px;
  font-weight: 400;
}
.rx_login_wrapper .login_footer p {
  display: block;
  font-size: 14px;
  color: #fff;
  line-height: 21px;
  margin: 20px 0 0;
  font-weight: 400;
}
.rx_login_wrapper .login_footer p a {
  text-decoration: underline;
}

.register_btn {
  padding-top: 10px;
}

.forgot_btn {
  padding-top: 10px;
  padding-left: 224px;
}
/************ Responsive ************/
@media (max-width: 767px) {
  .rx_login_wrapper .rx_login_inner {
    padding: 36px 20px;
  }
  .rx_login_wrapper .rx_login_inner .login_header p {
    font-size: 18px;
    line-height: 24px;
  }
  .rx_login_wrapper .rx_login_inner .login_header .login_logo {
    margin-bottom: 30px;
  }
  .rx_login_wrapper .rx_login_inner .login_header .login_logo img {
    max-width: 180px;
  }
} /*# sourceMappingURL=login.css.map */
