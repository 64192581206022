* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html,
body {
  font-family: 'Poppins', sans-serif;
  color: #323a46;
  background-color: #f5f6f9;
  padding: 0;
  margin: 0;
  font-size: 16px;
  height: 100%;
  scroll-behavior: smooth;
  overflow-x: hidden;
}

*,
*::before,
*::after {
  box-sizing: border-box;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
}

ul {
  padding: 0;
  margin: 0;
  list-style: none;
}

a,
a:hover,
a:focus {
  text-decoration: none;
}

.container {
  max-width: 1663px;
}

.rx_btn {
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 144px;
  height: 54px;
  font-size: 22px;
  font-weight: 600;
  border-radius: 4px;
  background-color: #44cf9c;
  color: #fff !important;
  text-transform: capitalize;
  font-family: 'Poppins', sans-serif;
}
.rx_btn:hover {
  color: #fff;
}

.row {
  padding-bottom: 25px;
}
.btn_cancel {
  color: #00539f !important;
  background-color: #f3f7f9;
}

.disable-anchor {
  pointer-events: none;
}
.cursor-pointer {
  cursor: pointer;
}

.disable-tag {
  cursor: not-allowed;
  opacity: 0.85;
}

.drag_area {
  position: relative;
  /* width: 990px; */
  height: 50px;
  display: flex;
  padding: 20px;
  justify-content: center;
  align-items: center;
  margin-right: 2%;
  background-color: #d9dde0;
  border: 1px;
  border: #171818;
  border-style: dotted;
  border-radius: 12px;
  text-align: center;
  /* align-items: center; */
  /* justify-content: center; */
}
.redirect_icon {
  width: 20px;
  height: 20px;
}
.box-shadow-none {
  box-shadow: none !important;
}

.drag_area_table {
  display: flex;
  padding-top: 15px;
  padding-right: 30px;
  padding-left: 10px;
  margin-right: 3%;
  background-color: #6c757d;
  border: 2px;
  border: #171818;
  border-style: dotted;
  border-radius: 12px;
  text-align: center;
  /* align-items: center; */
  /* justify-content: center; */
}

.dash_btn {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  max-width: 216px;
  height: 50px;
  background-color: #2c7be5;
  color: #fff;
  font-size: 18px;
  text-transform: capitalize;
  border-radius: 4px;
  font-family: 'Poppins', sans-serif;
  margin-left: 7px;
  margin-right: 7px;
  /* background-image: linear-gradient(#2c7be5, #fff); */
}
.dash_btn::-webkit-file-upload-button {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  max-width: 216px;
  height: 50px;
  background-color: #2c7be5;
  color: #fff;
  font-size: 18px;
  text-transform: capitalize;
  border-radius: 4px;
  font-family: 'Poppins', sans-serif;
  border: none;
  margin-right: 7px;
  margin-left: 7px;
  padding-right: 20px;
  cursor: pointer;
  /* background-image: linear-gradient(#2c7be5, #000); */
}

.dash_btn:hover {
  color: #fff;
}

.table_white_bg {
  background-color: #fff;
  padding: 19px 15px 20px;
  border-radius: 12px;
  box-shadow: 0 0 12px rgba(0, 0, 0, 0.08);
}

.login_btn {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 45px;
  color: #fff;
  background-color: #3283f6;
  text-transform: capitalize;
  font-size: 16px;
  font-weight: 700;
  border-radius: 4px;
}
.login_btn:hover {
  color: #fff;
}

.rx_form_block {
  margin-bottom: 12px;
}
.rx_form_block .rx_form_field {
  width: 100%;
  height: 54px;
  border: 1px solid #ced4da;
  border-radius: 4px;
  outline: none;
  padding: 0 20px;
  font-size: 14px;
  color: #707070;
}
.rx_form_block .rx_form_label {
  font-size: 14px;
  font-weight: 700;
  color: #707070;
  line-height: 21px;
  margin-bottom: 5px;
}
.rx_form_block .rx_form_label_2 {
  font-size: 22px;
  font-weight: 500;
  color: #6c757d;
  line-height: 33px;
  margin-bottom: 6px;
  position: relative;
}
.rx_form_block .rx_form_label_2 span {
  font-size: 18px;
}
.rx_form_block .rx_form_label_2 .star {
  position: absolute;
  top: 2px;
  left: -13px;
  color: #d60a0a;
  font-size: 16px !important;
}
.rx_form_block .custom_select {
  width: 100%;
  height: 55px;
  position: relative;
  border: 1px solid #ced4da;
  border-radius: 4px;
}
.rx_form_block .custom_select::after {
  content: url(../images/icons/caret.svg);
  position: absolute;
  top: 50%;
  right: 22px;
  transform: translateY(-50%);
  pointer-events: none;
}
.rx_form_block .custom_select select {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  width: 100%;
  height: 100%;
  border: none;
  outline: none;
  font-size: 14px;
  padding: 0 20px;
  width: 100%;
  background: #fff;
  color: #6c757d;
  border-radius: 4px;
}
.rx_form_block .custom_select select option {
  color: #6c757d;
}

.rx_form_checkbox label {
  position: relative;
  font-size: 14px;
  line-height: 21px;
  font-weight: 400;
  color: #707070;
  padding-left: 35px;
  margin-bottom: 0;
  cursor: pointer;
}
.rx_form_checkbox label::before {
  content: '';
  position: absolute;
  top: 50%;
  left: 0;
  width: 24px;
  height: 22px;
  border: 1px solid #ced4da;
  border-radius: 4px;
  transform: translateY(-50%);
}
.rx_form_checkbox label::after {
  content: '';
  position: absolute;
  top: 6px;
  left: 4px;
  width: 16px;
  height: 8px;
  border-bottom: 3px solid #4b95d6;
  border-left: 3px solid #4b95d6;
  transform: rotate(-45deg);
  visibility: hidden;
  opacity: 1;
}
.rx_form_checkbox input {
  position: absolute;
  left: -99999999px;
}
.rx_form_checkbox input:checked ~ label::after {
  visibility: visible;
  opacity: 1;
}

.main_heading h1 {
  font-size: 32px;
  line-height: 48px;
  font-weight: 700;
  color: #323a46;
  margin-bottom: 20px;
}

.sub_heading h3 {
  font-size: 20px;
  line-height: 30px;
  font-weight: 700;
  color: #323a46;
}

.border_bt_none {
  border-bottom: none !important;
}

.mt_33 {
  margin-top: 33px;
}

input[type='date'] {
  min-width: 96%;
}

input[type='date']::-webkit-calendar-picker-indicator {
  -webkit-appearance: none;
  appearance: none;
  content: '';
  position: absolute;
  right: 2px;
  top: 2px;
  width: 35px;
  height: 35px;
  z-index: 0;
}

.h6,
h6 {
  font-size: 1rem;
  text-align: center;
  padding-top: 10px;
}

.analytics-report-info {
  text-align: center;
}

.analytics-report-modal {
  width: 90vw;
  max-width: 90vw;
}

.empty_div {
  /* width: 440px; */
  width: 430px;
}
.date_picker {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  max-width: 170px;
  height: 30px;
  color: #1e1e1eb6;
  /* text-align: center; */
  padding-left: 30px;
  font-size: 16px;
  text-transform: capitalize;
  border-radius: 4px;
  font-family: 'Poppins', sans-serif;
  margin-left: 7px;
  margin-right: 7px;
}

.accuracy_error_dash_btn {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  max-width: 170px;
  height: 30px;
  background-color: #2c7be5;
  color: #fff;
  font-size: 16px;
  text-transform: capitalize;
  border-radius: 4px;
  font-family: 'Poppins', sans-serif;
  margin-left: 7px;
  margin-right: 7px;
  /* background-image: linear-gradient(#2c7be5, #fff); */
}

/* Responsive Css */
@media (max-width: 1199px) {
  .rx_form_block .rx_form_label_2 {
    font-size: 18px;
  }

  .main_heading h1 {
    font-size: 24px;
    line-height: 42px;
    margin-bottom: 12px;
  }
}
@media (max-width: 768px) {
  .main_heading h1 {
    font-size: 22px;
    line-height: 32px;
  }

  .rx_form_block .custom_select select {
    padding: 0 15px;
  }
  .rx_form_block .rx_form_field {
    padding: 0 15px;
  }
}
@media (max-width: 767px) {
  .rx_form_block .rx_form_label_2 {
    font-size: 16px;
    line-height: 24px;
  }

  .dash_btn {
    max-width: 178px;
    height: 46px;
    font-size: 15px;
  }
}
@media (max-width: 575px) {
  .rx_btn {
    min-width: 100px;
    height: 42px;
    font-size: 16px;
  }
} /*# sourceMappingURL=common.css.map */

/* Added classes */
.w-fit-content {
  width: fit-content;
}
.text-red {
  color: #dc3545 !important;
}
.text-orange {
  color: #ef5400 !important;
}
.text-orange-light {
  /* Ineligible */
  color: #bb7b00 !important; 
}
.text-blue {
  /* color: #ffc000 !important; */
  color: #4b95d6 !important;
}
.text-yellow{
  color: #ffc107  !important;
}
.text-purple{
  color: purple  !important;
}

.apexcharts-legend{
  padding:0px !important;
}

/* //    color: #4b95d6;
//   color: #81beff;
//   color: #dc3545;
//   color: #ef5400;
//   color: #ffc107; */